import { slugify } from 'utils/slugify'
import * as ExamsIcons from '../components/exams'
import { PoliciaForalNavarra } from '../components/exams/policia-foral-navarra'
import { AuxiliarAdministrativoNavarra } from '../components/exams'

export const getExamIcon = (examName: string, strict = false) => {
  const name = slugify(examName)
  const obj = {
    aena: ExamsIcons.BomberoAena,
    'bomberos-comunidad-de-madrid': ExamsIcons.BomberoMadrid,
    'auxiliar-administrativo-comunidad-de-madrid':
      ExamsIcons.AdministrativoMadrid,
    'bomberos-generalitat-de-catalunya': ExamsIcons.BomberoCatalunya,
    'bomberos-andalucia': ExamsIcons.BomberoAndalucia,
    'policia-local-andalucia': ExamsIcons.BomberoAndalucia,
    'auxiliar-administrativo-andalucia': ExamsIcons.BomberoAndalucia,
    'bomberos-comunidad-valenciana': ExamsIcons.BomberoValencia,
    'auxiliar-administrativo-generalitat-valenciana':
      ExamsIcons.AuxiliarAdministrativoGeneralitatValenciana,
    'auxiliar-administrativo-ayuntamiento-de-madrid':
      ExamsIcons.BomberoAytoMadrid,
    'especialista-ayto-madrid': ExamsIcons.BomberoAytoMadrid,
    'conductor-ayto-madrid': ExamsIcons.BomberoAytoMadrid,
    'temario-abierto': ExamsIcons.TemarioAbierto,
    'bomberos-navarra': ExamsIcons.BomberoNavarra,
    'policia-foral-de-navarra': ExamsIcons.PoliciaForalNavarra,
    'policia-nacional-escala-basica': ExamsIcons.PoliciaNacional,
    'ayuntamiento-de-valencia': ExamsIcons.BomberoAytoValencia,
    cecop: ExamsIcons.BomberoCecop,
    'guardia-civil': ExamsIcons.GuardiaCivil,
    'manual-ceis': ExamsIcons.Ceis,
    'mossos-desquadra': ExamsIcons.Mossos,
    'policia-local': ExamsIcons.Local,
    correos: ExamsIcons.Correos,
    'administrativo-del-estado': ExamsIcons.Spain,
    'auxiliar-administrativo-del-estado': ExamsIcons.SpainGray,
    'ingles-fcs': ExamsIcons.EnglishFcs,
    'memoria-ninja': ExamsIcons.MemoriaExam,
    'castilla-y-leon': ExamsIcons.BomberoCastilla,
    'basico-oposiciones': ExamsIcons.VideosCortos,
    mir: ExamsIcons.Mir,
    eir: ExamsIcons.Eir,
    'policia-local-catalunya': ExamsIcons.BomberoCatalunya,
    'policia-local-comunidad-valenciana': ExamsIcons.BomberoValencia,
    'constitucion-espanola': ExamsIcons.Ce,
    'guardia-urbana-de-barcelona': ExamsIcons.GuardiaUrbanaBarcelona,
    'policia-municipal-de-madrid': ExamsIcons.PoliciaMunicipalMadrid,
    'policia-local-comunidad-de-madrid': ExamsIcons.AdministrativoMadrid,
    'corporaciones-locales-ayuntamientos-y-provincias':
      ExamsIcons.AdministrativoCorporacionesLocales,
    default: ExamsIcons.NinjaDefault,
    'metro-de-madrid': ExamsIcons.MetroDeMadrid,
    'auxiliar-administrativo-de-navarra':
      ExamsIcons.AuxiliarAdministrativoNavarra,
    'administrativo-comunidad-de-madrid': ExamsIcons.AdministrativoMadrid,
    'administrativo-andalucia': ExamsIcons.BomberoAndalucia,
    'administrativo-generalitat-valenciana': ExamsIcons.AuxiliarAdministrativoGeneralitatValenciana
  }

  if (!obj[name]) {
    if (strict) {
      return null
    }
  }

  return obj[name] || ExamsIcons.BomberoGeneral
}
