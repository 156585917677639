import React, { useMemo } from 'react'
import { platformsIcons } from '@/utils/platform-icons'

export function PlatformIcon({
  platformName,
  url,
  ...props
}: {
  platformName?: string
  url?: string
  css?: any
  className?: string
}) {
  const Icon = useMemo(() => {
    if (platformName) {
      return (
        platformsIcons[platformName.toLowerCase()] || platformsIcons.policia
      )
    } else if (url) {
      const urlLower = url.toLowerCase()

      if (urlLower.includes('bombero')) {
        return platformsIcons['bombero']
      }
      if (urlLower.includes('policia')) {
        return platformsIcons.policia
      }
      if (urlLower.includes('correo')) {
        return platformsIcons.correos
      }
      if (urlLower.includes('administrativo')) {
        return platformsIcons.administrativo
      }
      if (urlLower.includes('memoria')) {
        return platformsIcons.memoria
      }
      if (urlLower.includes('academia-mir-eir')) {
        return platformsIcons['mir/eir']
      }
      if (urlLower.includes('constitucion-espanola')) {
        return platformsIcons['constitucion-espanola']
      }
      if (urlLower.includes('ninja')) {
        return platformsIcons.ninja
      }
    }

    return platformsIcons.policia
  }, [platformName, url])

  return <Icon {...props} />
}
